export enum Company {
  name = "onepole"
}

export enum PriceLevel {
  None = "None",
  Level_1 = "Level-1",
  Level_2 = "Level-2",
  Level_3 = "Level-3",
  Level_4 = "Level-4",
  Level_5 = "Level-5",
  Level_6 = "Level-6",
  Level_7 = "Level-7",
  Level_8 = "Level-8",
  Level_9 = "Level-9",
  Level_10 = "Level-10"
}

export enum RedirectOptions {
  home = "/",
  profile = "/user/profile",
  orders = "/user/orders",
  login = "/auth/login",
  forgotPassword = "/auth/forgot-password",
  products = "/products",
  categories = "/products/categories",
  configurator1 = "/configurator/stage/1",
  configurator2 = "/configurator/stage/2",
  configurator3 = "/configurator/stage/3",
  configurator4 = "/configurator/stage/4",
  configurator5 = "/configurator/stage/5",
  cart = "/cart",
  notFound = "/404"
}

export enum ImageSize {
  large = "large",
  medium = "medium",
  small = "small",
  thumbnail = "thumbnail"
}

export enum ImageLayout {
  fixed = "fixed",
  fill = "fill",
  intrinsic = "intrinsic",
  responsive = "responsive"
}

export enum QualityLevel {
  Basic = "Basic",
  Premium = "Premium",
  PremiumPlus = "PremiumPlus"
}

export enum ConfigLayoutType {
  "Type_1+0" = "Type_1+0",
  "Type_1+1" = "Type_1+1",
  "Type_2+0" = "Type_2+0",
  "Type_2+1" = "Type_2+1",
  "Type_2+2" = "Type_2+2",
  "Type_Quattro" = "Type_Quattro"
}

export enum Pages {
  nav = "nav",
  footer = "footer",
  home = "home",
  products = "products",
  category = "category",
  accessory = "accessory",
  configurator = "configurator",
  references = "references",
  contact = "contact",
  login = "login",
  register = "register",
  forgot = "forgot",
  reset = "reset",
  mypage = "my-page",
  myProfile = "my-profile",
  order = "my-order",
  profile = "my-profile",
  cart = "cart",
  checkout = "checkout",
  thankyou = "thankyou",
  password = "password",
  form = "form",
  formValidation = "form-validation",
  privacyStatement = "privacy-statement",
  contactInfo = "contact-info"
}

export enum InvalidErrorStrings {
  user = "invalid user",
  order = "invalid order"
}

export enum KeyCodes {
  enter = "Enter",
  escape = "Escape",
  arrowLeft = "ArrowLeft",
  arrowRight = "ArrowRight",
  arrowUp = "ArrowUp",
  arrowDown = "ArrowDown",
  none = ""
}

export enum ProductDetailTabs {
  downloads = "downloads",
  specifications = "specifications",
  renderer = "3d"
}

export enum ProductScroll {
  top = "top",
  render = "renderer",
  details = "details",
  bottom = "bottom"
}

export const AutofillFields = {
  name: "name",
  email: "email",
  phone: "phone",
  address: "address",
  password: "password"
} as const;

export const validationErrorHelper = {
  validation: {
    text: "validation",
    password: {
      required: "is required",
      match: "match",
      invalidCurrentPassword: "current password is invalid",
      characters: "characters"
    }
  }
};

export enum ConfigStage {
  type = "type",
  quality = "quality",
  layout = "layout",
  brackets = "brackets",
  preview = "preview"
}

export enum Channel {
  internal = "internal",
  external = "external"
}
