import { CountryInformation } from "./Country";
import {
  CountryCode,
  CountryName,
  CurrencyCode,
  CurrencySide,
  DomainExtension,
  LanguageName,
  LocaleCode
} from "@utils/Country/countryEnums";

export const countryList: CountryInformation[] = [
  {
    name: CountryName.Albania,
    language: LanguageName.Albania,
    domain: DomainExtension.Albania,
    locale: LocaleCode.Albania,
    countryCode: CountryCode.Albania,
    currency: CurrencyCode.ALL,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "L",
    hasLocale: false
  },
  {
    name: CountryName.Andorra,
    language: LanguageName.Andorra,
    domain: DomainExtension.Andorra,
    locale: LocaleCode.Andorra,
    countryCode: CountryCode.Andorra,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.Armenia,
    language: LanguageName.Armenia,
    domain: DomainExtension.Armenia,
    locale: LocaleCode.Armenia,
    countryCode: CountryCode.Armenia,
    currency: CurrencyCode.AMD,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "֏",
    hasLocale: false
  },
  {
    name: CountryName.Austria,
    language: LanguageName.Austria,
    domain: DomainExtension.Austria,
    locale: LocaleCode.Austria,
    countryCode: CountryCode.Austria,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: true
  },
  {
    name: CountryName.Azerbaijan,
    language: LanguageName.Azerbaijan,
    domain: DomainExtension.Azerbaijan,
    locale: LocaleCode.Azerbaijan,
    countryCode: CountryCode.Azerbaijan,
    currency: CurrencyCode.AZN,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "₼",
    hasLocale: false
  },
  {
    name: CountryName.Belarus,
    language: LanguageName.Belarus,
    domain: DomainExtension.Belarus,
    locale: LocaleCode.Belarus,
    countryCode: CountryCode.Belarus,
    currency: CurrencyCode.BYN,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "Br",
    hasLocale: false
  },
  {
    name: CountryName.Belgium,
    language: LanguageName.Belgium,
    domain: DomainExtension.Belgium,
    locale: LocaleCode.Belgium,
    countryCode: CountryCode.Belgium,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.BosniaAndHerzegovina,
    language: LanguageName.BosniaAndHerzegovina,
    domain: DomainExtension.BosniaAndHerzegovina,
    locale: LocaleCode.BosniaAndHerzegovina,
    countryCode: CountryCode.BosniaAndHerzegovina,
    currency: CurrencyCode.BAM,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "KM",
    hasLocale: false
  },
  {
    name: CountryName.Bulgaria,
    language: LanguageName.Bulgaria,
    domain: DomainExtension.Bulgaria,
    locale: LocaleCode.Bulgaria,
    countryCode: CountryCode.Bulgaria,
    currency: CurrencyCode.BGN,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "лв",
    hasLocale: false
  },
  {
    name: CountryName.Croatia,
    language: LanguageName.Croatia,
    domain: DomainExtension.Croatia,
    locale: LocaleCode.Croatia,
    countryCode: CountryCode.Croatia,
    currency: CurrencyCode.HRK,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "kn",
    hasLocale: false
  },
  {
    name: CountryName.Cyprus,
    language: LanguageName.Cyprus,
    domain: DomainExtension.Cyprus,
    locale: LocaleCode.Cyprus,
    countryCode: CountryCode.Cyprus,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.CzechRepublic,
    language: LanguageName.CzechRepublic,
    domain: DomainExtension.CzechRepublic,
    locale: LocaleCode.CzechRepublic,
    countryCode: CountryCode.CzechRepublic,
    currency: CurrencyCode.CZK,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "Kč",
    hasLocale: false
  },
  {
    name: CountryName.Denmark,
    language: LanguageName.Denmark,
    domain: DomainExtension.Denmark,
    locale: LocaleCode.Denmark,
    countryCode: CountryCode.Denmark,
    currency: CurrencyCode.DKK,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: true
  },
  {
    name: CountryName.Estonia,
    language: LanguageName.Estonia,
    domain: DomainExtension.Estonia,
    locale: LocaleCode.Estonia,
    countryCode: CountryCode.Estonia,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.Finland,
    language: LanguageName.Finland,
    domain: DomainExtension.Finland,
    locale: LocaleCode.Finland,
    countryCode: CountryCode.Finland,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.France,
    language: LanguageName.France,
    domain: DomainExtension.France,
    locale: LocaleCode.France,
    countryCode: CountryCode.France,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.Georgia,
    language: LanguageName.Georgia,
    domain: DomainExtension.Georgia,
    locale: LocaleCode.Georgia,
    countryCode: CountryCode.Georgia,
    currency: CurrencyCode.GEL,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "₾",
    hasLocale: false
  },
  {
    name: CountryName.Germany,
    language: LanguageName.Germany,
    domain: DomainExtension.Germany,
    locale: LocaleCode.Germany,
    countryCode: CountryCode.Germany,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: true
  },
  {
    name: CountryName.Greece,
    language: LanguageName.Greece,
    domain: DomainExtension.Greece,
    locale: LocaleCode.Greece,
    countryCode: CountryCode.Greece,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.Hungary,
    language: LanguageName.Hungary,
    domain: DomainExtension.Hungary,
    locale: LocaleCode.Hungary,
    countryCode: CountryCode.Hungary,
    currency: CurrencyCode.HUF,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "Ft",
    hasLocale: false
  },
  {
    name: CountryName.Iceland,
    language: LanguageName.Iceland,
    domain: DomainExtension.Iceland,
    locale: LocaleCode.Iceland,
    countryCode: CountryCode.Iceland,
    currency: CurrencyCode.ISK,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "kr",
    hasLocale: false
  },
  {
    name: CountryName.Ireland,
    language: LanguageName.Ireland,
    domain: DomainExtension.Ireland,
    locale: LocaleCode.Ireland,
    countryCode: CountryCode.Ireland,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.Start,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.Italy,
    language: LanguageName.Italy,
    domain: DomainExtension.Italy,
    locale: LocaleCode.Italy,
    countryCode: CountryCode.Italy,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.Kazakhstan,
    language: LanguageName.Kazakhstan,
    domain: DomainExtension.Kazakhstan,
    locale: LocaleCode.Kazakhstan,
    countryCode: CountryCode.Kazakhstan,
    currency: CurrencyCode.KZT,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "₸",
    hasLocale: false
  },
  {
    name: CountryName.Kosovo,
    language: LanguageName.Kosovo,
    domain: DomainExtension.Kosovo,
    locale: LocaleCode.Kosovo,
    countryCode: CountryCode.Kosovo,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.Latvia,
    language: LanguageName.Latvia,
    domain: DomainExtension.Latvia,
    locale: LocaleCode.Latvia,
    countryCode: CountryCode.Latvia,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.Liechtenstein,
    language: LanguageName.Liechtenstein,
    domain: DomainExtension.Liechtenstein,
    locale: LocaleCode.Liechtenstein,
    countryCode: CountryCode.Liechtenstein,
    currency: CurrencyCode.CHF,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "CHF",
    hasLocale: true
  },

  {
    name: CountryName.Lithuania,
    language: LanguageName.Lithuania,
    domain: DomainExtension.Lithuania,
    locale: LocaleCode.Lithuania,
    countryCode: CountryCode.Lithuania,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.Luxembourg,
    language: LanguageName.Luxembourg,
    domain: DomainExtension.Luxembourg,
    locale: LocaleCode.Luxembourg,
    countryCode: CountryCode.Luxembourg,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.Macedonia,
    language: LanguageName.Macedonia,
    domain: DomainExtension.Macedonia,
    locale: LocaleCode.Macedonia,
    countryCode: CountryCode.Macedonia,
    currency: CurrencyCode.MKD,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "ден",
    hasLocale: false
  },
  {
    name: CountryName.Malta,
    language: LanguageName.Malta,
    domain: DomainExtension.Malta,
    locale: LocaleCode.Malta,
    countryCode: CountryCode.Malta,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.Moldova,
    language: LanguageName.Moldova,
    domain: DomainExtension.Moldova,
    locale: LocaleCode.Moldova,
    countryCode: CountryCode.Moldova,
    currency: CurrencyCode.MDL,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "MDL",
    hasLocale: false
  },
  {
    name: CountryName.Monaco,
    language: LanguageName.Monaco,
    domain: DomainExtension.Monaco,
    locale: LocaleCode.Monaco,
    countryCode: CountryCode.Monaco,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.Montenegro,
    language: LanguageName.Montenegro,
    domain: DomainExtension.Montenegro,
    locale: LocaleCode.Montenegro,
    countryCode: CountryCode.Montenegro,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.Netherlands,
    language: LanguageName.Netherlands,
    domain: DomainExtension.Netherlands,
    locale: LocaleCode.Netherlands,
    countryCode: CountryCode.Netherlands,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: true
  },
  {
    name: CountryName.Norway,
    language: LanguageName.Norway,
    domain: DomainExtension.Norway,
    locale: LocaleCode.Norway,
    countryCode: CountryCode.Norway,
    currency: CurrencyCode.NOK,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "kr",
    hasLocale: true
  },
  {
    name: CountryName.Poland,
    language: LanguageName.Poland,
    domain: DomainExtension.Poland,
    locale: LocaleCode.Poland,
    countryCode: CountryCode.Poland,
    currency: CurrencyCode.PLN,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "zł",
    hasLocale: false
  },
  {
    name: CountryName.Portugal,
    language: LanguageName.Portugal,
    domain: DomainExtension.Portugal,
    locale: LocaleCode.Portugal,
    countryCode: CountryCode.Portugal,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.Romania,
    language: LanguageName.Romania,
    domain: DomainExtension.Romania,
    locale: LocaleCode.Romania,
    countryCode: CountryCode.Romania,
    currency: CurrencyCode.RON,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "lei",
    hasLocale: false
  },
  {
    name: CountryName.Russia,
    language: LanguageName.Russia,
    domain: DomainExtension.Russia,
    locale: LocaleCode.Russia,
    countryCode: CountryCode.Russia,
    currency: CurrencyCode.RUB,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "₽",
    hasLocale: false
  },
  {
    name: CountryName.SanMarino,
    language: LanguageName.SanMarino,
    domain: DomainExtension.SanMarino,
    locale: LocaleCode.SanMarino,
    countryCode: CountryCode.SanMarino,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.Serbia,
    language: LanguageName.Serbia,
    domain: DomainExtension.Serbia,
    locale: LocaleCode.Serbia,
    countryCode: CountryCode.Serbia,
    currency: CurrencyCode.RSD,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "RSD",
    hasLocale: false
  },
  {
    name: CountryName.Slovakia,
    language: LanguageName.Slovakia,
    domain: DomainExtension.Slovakia,
    locale: LocaleCode.Slovakia,
    countryCode: CountryCode.Slovakia,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.Slovenia,
    language: LanguageName.Slovenia,
    domain: DomainExtension.Slovenia,
    locale: LocaleCode.Slovenia,
    countryCode: CountryCode.Slovenia,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },

  {
    name: CountryName.Spain,
    language: LanguageName.Spain,
    domain: DomainExtension.Spain,
    locale: LocaleCode.Spain,
    countryCode: CountryCode.Spain,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  },
  {
    name: CountryName.Sweden,
    language: LanguageName.Sweden,
    domain: DomainExtension.Sweden,
    locale: LocaleCode.Sweden,
    countryCode: CountryCode.Sweden,
    currency: CurrencyCode.SEK,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: true
  },
  {
    name: CountryName.Switzerland,
    language: LanguageName.Switzerland,
    domain: DomainExtension.Switzerland,
    locale: LocaleCode.Switzerland,
    countryCode: CountryCode.Switzerland,
    currency: CurrencyCode.CHF,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "CHF",
    hasLocale: true
  },
  {
    name: CountryName.Turkey,
    language: LanguageName.Turkey,
    domain: DomainExtension.Turkey,
    locale: LocaleCode.Turkey,
    countryCode: CountryCode.Turkey,
    currency: CurrencyCode.TRY,
    currencyPlacement: CurrencySide.Start,
    currencySymbol: "₺",
    hasLocale: false
  },
  {
    name: CountryName.Ukraine,
    language: LanguageName.Ukraine,
    domain: DomainExtension.Ukraine,
    locale: LocaleCode.Ukraine,
    countryCode: CountryCode.Ukraine,
    currency: CurrencyCode.UAH,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "₴",
    hasLocale: false
  },
  {
    name: CountryName.UnitedKingdom,
    language: LanguageName.UnitedKingdom,
    domain: DomainExtension.UnitedKingdom,
    locale: LocaleCode.UnitedKingdom,
    countryCode: CountryCode.UnitedKingdom,
    currency: CurrencyCode.GBP,
    currencyPlacement: CurrencySide.Start,
    currencySymbol: "£",
    hasLocale: true
  },
  {
    name: CountryName.VaticanCity,
    language: LanguageName.VaticanCity,
    domain: DomainExtension.VaticanCity,
    locale: LocaleCode.VaticanCity,
    countryCode: CountryCode.VaticanCity,
    currency: CurrencyCode.EUR,
    currencyPlacement: CurrencySide.End,
    currencySymbol: "€",
    hasLocale: false
  }
];
